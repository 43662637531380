function setEqualHeight() {
    var skelBreakpoints = skel.vars.stateId;
    if (skelBreakpoints.search("/large") === -1) {
        $("#animation-element-1,#animation-element-2").matchHeight();
    } else {
        $("#animation-element-1").height("");
        $("#animation-element-2").height("");
    }
    $(".grid-element-content-single-content > header").matchHeight();
    $(".grid-element-content-single-detail").matchHeight();
    $(".grid-element-content-header-title").matchHeight();
    $(".grid-element-content-detail").matchHeight();
}
$(window).on("load",function() {
    setEqualHeight();
});
$(window).on("resize", function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});
/******************************************************************************/
$(document).ready(function() {
    var width, height;
    $(".grid-element-content-single-image a").hover(function() {
        width = $(this).width();
        height = $(this).height();
        $(this).css("width", width + "px");
        $(this).css("height", height + "px");
        // $(this).find(".overlay").css("width", width + "px");
        // $(this).find(".overlay").css("height", height + "px");
    }, function() {
        $(this).css("width", "");
        $(this).css("height", "");
    });
});
/******************************************************************************/
/*
var $animation_elements = $("#page-1-2-container"),
    $window = $(window),
    $in_view_timeout = 500;
*/
/******************************************************************************/
/*
function set_in_view(el, i, window_height, window_top_position, window_bottom_position) {
    if (!isNumeric(i)) {
        i = 1;
    }
    if (el.length > 0) {
        var $el = $(this),
            element_height = el.outerHeight(),
            element_top_position = el.offset().top,
            element_bottom_position = (element_top_position + element_height);
        if ((element_bottom_position >= window_top_position) && (element_top_position <= window_bottom_position)) {
            i++;
            setTimeout(function() {
                    el.addClass("in-view");
                },
                i * $in_view_timeout);
        } else {
            el.removeClass("in-view");
        }
    }
    return i;
}
*/
/******************************************************************************/
/*
function check_if_in_view() {
    var window_height = $window.height(),
        window_top_position = $window.scrollTop(),
        window_bottom_position = window_top_position + window_height,
        i = 0,
        el;
*/
    /*
    $.each($animation_elements, function () {
    el = $(this);
    i = set_in_view(el,i,window_height,window_top_position,window_bottom_position);
    });
     */
/*
    el = $("#animation-element-1");
    i = set_in_view(el, i, window_height, window_top_position, window_bottom_position);
    el = $("#animation-element-2");
    i = set_in_view(el, i, window_height, window_top_position, window_bottom_position);
}
*/
/******************************************************************************/
/*
$window.on("scroll resize", check_if_in_view);
$window.trigger("scroll");
*/
